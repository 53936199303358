import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import useContentfulImage from "../hooks/useContentfulImage"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Layout from "../components/layout"
import { Trans, t} from "@lingui/macro"
import { I18nProvider, I18n } from "@lingui/react"
import catalogEs from "../locales/es/messages"
import catalogEn from "../locales/en/messages"
import catalogBr from "../locales/br/messages"
import catalogFr from "../locales/fr/messages"
import catalogIt from "../locales/it/messages"
import catalogDe from "../locales/de/messages"

const slugify = require("slugify")

const CustomComponent = ({ node }) => (
  <section>{documentToReactComponents(node, options)}</section>
)
const CustomH1 = ({ string, id }) => <h1 className="mt-4 mb-3">{string}</h1>
const CustomH2 = ({ string, id }) => (
  <h2 id={id} className="py-6">
    {string}
  </h2>
)
const CustomH3 = ({ string, id }) => (
  <h3 id={id} className="py-6">
    {string}
  </h3>
)
const CustomImageComponent = ({ title, description, fluid }) => (
  <Img
    className="mt-5 mb-5 mx-auto d-block"
    style={{ maxWidth: 854, maxHeight: 480 }}
    fluid={JSON.parse(fluid)}
    title={title}
    alt={title}
    fadeIn="false"
    loading="eager"
  />
)

const headers = []

const options = {
  renderMark: {
    [MARKS.CODE]: text => {
      if (text.length > 0) {
        return <pre style={{"backgroundColor":"gainsboro", "padding":"1rem", "fontFamily": "Courier, Helvetica, sans-serif"}}>{text}</pre>;
      }
    }
  },
  renderNode: {
    [BLOCKS.QUOTE]: node => {
      return <blockquote className="blockquote"><p className="mb-0" style={{"color":"salmon", "marginLeft":"2rem", "marginRight":"2rem"}}><strong>❝ {node.content[0].content[0].value} ❞</strong></p></blockquote>
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const myNode = node.data.target.fields.sectionBody["en-US"]
      const id = slugify(myNode.content[0].content[0].value, {
        lower: true,
        remove: /[*+~.()'"!:@]/g,
      })
      return <CustomComponent node={myNode} />
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const fluid = useContentfulImage(
        JSON.stringify(node.data.target.fields.file["en-US"].url)
      )
      const title = JSON.stringify(node.data.target.fields.description["en-US"])
      const description = JSON.stringify(
        node.data.target.fields.file["en-US"].description
      )
      return (
        <CustomImageComponent
          title={title}
          description={description}
          fluid={JSON.stringify(fluid)}
        />
      )
    },
    [BLOCKS.HEADING_1]: node => {
      const string = node.content[0].value
      return <CustomH1 string={string} />
    },
    [BLOCKS.HEADING_2]: node => {
      const string = node.content[0].value
      const id = slugify(node.content[0].value, {
        lower: true,
        remove: /[*+~.()'"!:@]/g,
      })
      headers.push(string)
      return <CustomH2 string={string} id={id} />
    },
    [BLOCKS.HEADING_3]: node => {
      const string = node.content[0].value
      const id = slugify(node.content[0].value, {
        lower: true,
        remove: /[*+~.()'"!:@]/g,
      })
      return <CustomH3 string={string} id={id} />
    },
    [INLINES.HYPERLINK]: node => {
      if (node.data.uri.includes("youtube.com/embed")) {
        return (
          <div className="item mt-5 mb-5">
            <div className="row justify-content-md-center">
              <div className="col-sm-12 col-md-10 col-lg-8">
                <div className="content-figure">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      src={node.data.uri}
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } else if (node.data.uri.startsWith("/")) {
        return (
          <h2>
            <Link to={node.data.uri} title={node.content[0].value}>
              {node.content[0].value}
            </Link>
          </h2>
        )
      } else {
        return (
          <a
            href={node.data.uri}
            target="_blank"
            rel="nofollow noopener noreferrer"
            title={node.content[0].value}
          >
            {node.content[0].value}
          </a>
        )
      }
    },
  },
}

export default props => (
  <>
    <I18nProvider
      language={props.pageContext.lang}
      catalogs={{
        es: catalogEs,
        en: catalogEn,
        it: catalogIt,
        de: catalogDe,
        br: catalogBr,
        fr: catalogFr,
      }}
    >
      <Layout
        lang={props.pageContext.lang}
        title={props.data.contentfulSeries.title}
        description={props.data.contentfulSeries.description.description}
        paths={props.pageContext.otherPaths}
        image={props.data.contentfulSeries.heroImage.file.url}
        datePublished={props.data.contentfulSeries.publishDate}
        dateModified={props.data.contentfulSeries.updatedAt}
        pageType="series-page"
      >
        <div className="container mt-5">
          <series-page>
            <h1>{props.data.contentfulSeries.title}</h1>
            <p style={{ color: "silver", fontSize: "0.8em" }}>
              <Link to="/" style={{ color: "slategray" }}>
                Home
              </Link>{" "}
              »{props.pageContext.lang !== "es" && " "}
              {props.pageContext.lang !== "es" && (
                <Link
                  to={`/${props.pageContext.lang}/`}
                  style={{ color: "slategray" }}
                >
                  {`${props.pageContext.lang}`.toUpperCase()}
                </Link>
              )}
              {props.pageContext.lang !== "es" && " »"}{" "}
              {props.pageContext.lang === "es" && (
                <I18n>
                  {({ i18n }) => (
                    <Link to={i18n._(t`/series/`)} style={{ color: "slategray" }}>
                      <Trans>Series</Trans>
                    </Link>
                  )}
                </I18n>
              )}{" "}
              {props.pageContext.lang !== "es" && (
                <I18n>
                {({ i18n }) => (
                  <Link
                  to={i18n._(t`/series/`)}
                    style={{ color: "slategray" }}
                  >
                   <Trans>Series</Trans> 
                  </Link>
                )}
                </I18n>
              )}{" "}
              » {props.data.contentfulSeries.title}
            </p>

            {props.data.contentfulSeries.heroImage && (
              <Img
                className="featured mb-4"
                title={props.data.contentfulSeries.heroImage.title}
                style={{ maxHeight: "18em" }}
                fluid={props.data.contentfulSeries.heroImage.fluid}
                alt={props.data.contentfulSeries.title}
                fadeIn="false"
                loading="eager"
              />
            )}

            <hr />
            {/* <section>
              <div id="faq-accordion" className="faq-accordion mx-auto">
                <div className="card border-0 mb-4 shadow-sm rounded">
                  <div className="card-header border-0 p-4 theme-bg-light rounded">
                    <h4 className="card-title mb-0">
                      <a
                        data-parent="#faq-accordion"
                        data-toggle="collapse"
                        className="card-toggle collapsed"
                        href="#toc"
                        aria-expanded="false"
                      >
                        <i className="fas fa-chevron-down"></i>
                        &nbsp;<Trans>Tabla de Contenidos</Trans>
                      </a>
                    </h4>
                  </div>

                  <div className="card-collapse collapse" id="toc">
                    <div className="card-body p-4 bg-white rounded">
                      <ul className="list-unstyled">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: generateTOC(props.data.contentfulSeries),
                          }}
                        ></div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            {documentToReactComponents( 
              props.data.contentfulSeries.richTextBody.json,
              options
            )}
          </series-page>
        </div>
      </Layout>
    </I18nProvider>
  </>
)

export const query = graphql`
  query($id: String!) {
    contentfulSeries(id: { eq: $id }) {
      h1
      lang
      title
      description {
        description
      }
      publishDate
      updatedAt
      heroImage {
        file {
          url
        }
        title
        fluid(maxHeight: 600, maxWidth: 1200, quality: 60, cropFocus: CENTER) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      richTextBody {
        json
      }
    }
  }
`

function generateTOC(post) {
  let h2s = ""
  let h3s = ""
  post.richTextBody.json.content.forEach(element => {
    if (element.nodeType === "heading-2") {
      if (h2s.length !== 0) {
        h2s = h2s + h3s + "</ul>"
      }
      const h2 = JSON.stringify(element.content[0].value)
      h2s =
        h2s +
        '<li><a title="' +
        h2.substring(1, h2.length - 1) +
        "\" href='#" +
        slugify(h2, {
          lower: true,
          remove: /[*+~.()'"!:@]/g,
        }) +
        '\' style="text-decoration:none;font-size:0.8rem">» ' +
        h2.substring(1, h2.length - 1) +
        "</a></li>" +
        '<ul className="list-unstyled">'
      h3s = ""
    } else if (element.nodeType === "heading-3") {
      const h3 = JSON.stringify(element.content[0].value)
      h3s =
        h3s +
        '<li><a title="' +
        h3.substring(1, h3.length - 1) +
        "\" href='#" +
        slugify(h3, {
          lower: true,
          remove: /[*+~.()'"!:@]/g,
        }) +
        '\' style="text-decoration:none;font-size:0.7rem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· ' +
        h3.substring(1, h3.length - 1) +
        "</a></li>"
    }
  })
  return h2s
}
